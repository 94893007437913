<!-- eslint-disable -->
<template>
  <loading v-model:active="isLoading" :is-full-page="fullPage" />
  <div class="row">
      <div class="col-sm-12 col-md-7">
        <div class="widget white-bg">
          <div class="padding-20 text-center">
            <img alt="Profile Picture" width="140" class="mar-btm margin-b-10 circle-border" :src="imgpreview">
            <p class="lead font-500 margin-b-0">{{ authRow.nama_lengkap }}</p>
            <p class="text-muted">{{ authRow.username }}</p>
            <p class="text-sm margin-b-0">Program Studi {{ authRow.nama_prodi }}</p>
            
          </div>
        </div>
        <div class="card">
          <div class="card-header card-default">
            <p id="idExsampel" class="text-muted" >
              Halaman update foto profil
            </p>
          </div>
          <div class="card-body padding-t-0">
            <form @submit.prevent="handleSubmit">
              <div class="form-group">
                <div
                  class="fileinput fileinput-new input-group col-md-12"
                  data-provides="fileinput"
                >
                  <span
                    class="input-group-addon btn btn-indigo btn-file "
                    style="border-radius: 4px 0px 0px 4px;"
                  >
                    <span class="fileinput-new">Foto</span>
                    <span class="fileinput-exists">Change</span>
                    <input
                      ref="file"
                      type="file"
                      @change="previewImage"
                    >
                  </span>
                  <a
                    href="#"
                    class="input-group-addon btn btn-danger  hover fileinput-exists"
                    data-dismiss="fileinput"
                  >Remove</a>
                  <div
                    class="form-control"
                    data-trigger="fileinput"
                  >
                    <span class="fileinput-filename" />
                  </div>
                </div>
                <span><code>Format .JPG</code></span>
              </div>

              <div class="form-group">
                <button class="btn btn-success btn-block">Upload Foto</button>
              </div>
            </form>
          </div>
        </div>
      </div>
  </div>
        
</template>

<script>
/* global $ */
import Loading from 'vue-loading-overlay'
import { useStore } from 'vuex'
import { getMhs, uploadFoto, fotoMahasiswa } from '@/services/mahasiswa.service'
import { ref } from 'vue'
export default {
  name: 'EditProfil',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user

    return {
      files: null,
      authRow: users,
      valName: {},
      loadData: false,
      loadImage: false,
      loadSubmit: false,
      fullPage: true,
      imgpreview: '/img/avtar-2.png',
      fileList: ref([]),
      errorMsg: ''
    }
  },
  computed: {
    isLoading: function () {
      const load = !!((this.loadData || this.loadSubmit || this.loadImage))
      return load
    },
    alertMsg: function () {
      const load = (this.valName.updated_at === null)
      return load
    }
  },
  created () {
    this.handleBiodata()
    this.handleFoto()
  },
  methods: {
    uploadFile (event) {
      this.files = event.target.files
    },
    async handleBiodata () {
      this.loadData = true
      getMhs({ nipd: this.authRow.username })
        .then((response) => {
          this.isLoading = false
          if (response.status !== 200) {
            this.showError = true
          } else {
            this.loadData = false
            this.valName = response.records
          }
        })
        .catch((error) => {
          this.errorMsg = (error.response) ? {} : {}
          this.loadData = false
        })
    },
    handleFoto () {
      this.loadImage = true
      fotoMahasiswa({ reg: this.authRow.id_reg_pd })
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response]))
          this.imgpreview = url
          this.loadImage = false
        })
        .catch((error) => {
          this.errorMsg = (error.response) ? {} : {}
          this.loadImage = false
        })
    },
    previewImage (event) {
      var input = event.target
      if (input.files) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.imgpreview = e.target.result
          this.fileList = this.$refs.file.files[0]
        }
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    handleSubmit () {
      this.loadData = true
      const formData = new FormData()
      formData.append('id_reg_pd', this.valName.id_mahasiswa)
      formData.append('files', this.fileList)
      uploadFoto(formData).then(response => {
        if (response.error_code !== 200) {
          $.toast({
            heading: 'Warning',
            text: response.messages,
            position: 'top-right',
            loaderBg: '#fff',
            icon: 'error',
            hideAfter: 3000,
            stack: 1
          })
        } else {
          $.toast({
            heading: 'success',
            text: 'Foto berhasil di unggah',
            position: 'top-right',
            loaderBg: '#fff',
            icon: 'success',
            hideAfter: 3000,
            stack: 1
          })
        }
        console.log(response)
        this.loadData = false
      }).catch((error) => {
        this.errorMsg = (error.response) ? {} : {}
        this.loadData = false
      })
    }
  }
}
</script>
